/* eslint-disable @typescript-eslint/naming-convention */

import { Establishment } from "../services/api";

export const Routes = {
  Home: "home",
  Menu: "menu",
  Contact: "contact",
  NotFound: "**",
};

export const AppLanguages = Object.values(Establishment.EnabledLanguagesEnum);

export const GoogleMapsApiKey = "AIzaSyDX10tbynYQ25P7B0dyEQD7VnIoo0v9RJA";

export const DefaultLanguage = Establishment.EnabledLanguagesEnum.Spanish;

export const MapLanguageToISOCode: {[key in Establishment.EnabledLanguagesEnum]: string} = {
  "CATALAN": "ca",
  "ENGLISH": "en",
  "FRENCH": "fr",
  "GERMAN": "de",
  "ITALIAN": "it",
  "PORTUGUESE": "pt",
  "SPANISH": "es",
};
