import { Establishment, Menu } from "src/app/services/api";

export interface PublicEstablishment extends Establishment {
  menus: Menu[];
}

export interface Establishments {
  [id: number]: Establishment;
}

export interface EstablishmentState {
  establishments: Establishments;
  selected: string;
  loading: boolean;
  notFound: boolean;
}

export const initialState: EstablishmentState = {
  establishments: {},
  selected: null,
  loading: false,
  notFound: false,
};
