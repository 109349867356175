import { Injectable } from "@angular/core";
import { createEffect, ofType } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import * as EstablishmentActions from "./actions";
import { EstablishmentsService } from "src/app/services/api";
import { Router } from "@angular/router";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { SeoService } from "src/app/services/seo/seo.service";
import { Action, Store } from "@ngrx/store";
import * as FromApp from "src/app/store/app/selectors";
import * as AppActions from "src/app/store/app/actions";
import { RootState } from "..";
import { MAP_LANGUAGE_ENUM_TO_ISO } from "src/app/utils/map-language-enum-to-iso";

@Injectable()
export class EstablishmentEffects {
  public loadOne$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(EstablishmentActions.loadOne),
      mergeMap(action =>
        this.establishmentService.establishmentPublicControllerFindOne(action.slug).pipe(
          map(establishment => EstablishmentActions.loadOneSuccess({ establishment })),
          catchError((error: HttpErrorResponse) => of(EstablishmentActions.loadOneFailure({ error }))),
        ),
      ),
    ),
  );

  public loadOneSuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(EstablishmentActions.loadOneSuccess),
      tap(({ establishment }) => {
        this.setAccentColor(establishment.color || "#20B158");
        this.seoService.setMetadata(establishment.name, establishment.description, establishment.image || establishment.logo);
        this.store.dispatch(AppActions.sendEstablishmentVisit({ establishmentId: establishment.id }));
      }),
    ), { dispatch: false },
  );

  public loadOneFailure$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(EstablishmentActions.loadOneFailure),
      withLatestFrom(this.store.select(FromApp.selectLanguage)),
      tap(([{ error }, language]) => {
        this.seoService.setMetadata();

        if (error.status === HttpStatusCode.NotFound) {
          this.router.navigateByUrl("");
        } else if (error.status === HttpStatusCode.PaymentRequired) {
          window.location.href = `https://ohmymenu.com/${MAP_LANGUAGE_ENUM_TO_ISO[language]}`;
        }
      })
    ), { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly establishmentService: EstablishmentsService,
    private readonly router: Router,
    private readonly seoService: SeoService,
  ) {}

  private setAccentColor(color: string): void {
    document.documentElement.style.setProperty("--primary-color", color);
    document.documentElement.style.setProperty("--primary-color-rgb", this.hexToRgb(color).toString());

    if (this.isColorLight(color)) {
      document.documentElement.style.setProperty("--primary-color-contrast", "#222222");
      document.documentElement.style.setProperty("--primary-color-contrast-rgb", this.hexToRgb("#222222").toString());
    } else {
      document.documentElement.style.setProperty("--primary-color-contrast", "#ffffff");
      document.documentElement.style.setProperty("--primary-color-contrast-rgb", this.hexToRgb("#ffffff").toString());
    }
  }

  private hexToRgb(color: string): [number, number, number] {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
  }

  private isColorLight(color: string): boolean {
    if (color[0] === "#") color = color.slice(1);
    const R = parseInt(color.substring(0, 2), 16);
    const G = parseInt(color.substring(2, 4), 16);
    const B = parseInt(color.substring(4, 6), 16);

    // HSP (Highly Sensitive Poo)
    const hsp = Math.sqrt(0.299 * (R * R) + 0.587 * (G * G) + 0.114 * (B * B));
    return hsp > 127.5;
  }
}
