import { DOCUMENT, registerLocaleData } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { createEffect, ofType } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { Establishment, StatisticsService } from "src/app/services/api";
import { TranslateService } from "@ngx-translate/core";
import * as AppActions from "./actions";
import * as FromApp from "./selectors";
import catalan from "@angular/common/locales/ca";
import english from "@angular/common/locales/en";
import french from "@angular/common/locales/fr";
import german from "@angular/common/locales/de";
import italian from "@angular/common/locales/it";
import portuguese from "@angular/common/locales/pt";
import spanish from "@angular/common/locales/es";
import { Action, Store } from "@ngrx/store";
import { RootState } from "..";

@Injectable()
export class AppEffects {
  public changeLanguage$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.changeLanguage),
      withLatestFrom(this.store.select(FromApp.selectLanguage)),
      map(([{ language }, currentLang]) => {
        const shouldReload = language !== currentLang;

        switch (language) {
          case Establishment.EnabledLanguagesEnum.Catalan:
            registerLocaleData(catalan);
            break;
          case Establishment.EnabledLanguagesEnum.English:
            registerLocaleData(english);
            break;
          case Establishment.EnabledLanguagesEnum.French:
            registerLocaleData(french);
            break;
          case Establishment.EnabledLanguagesEnum.German:
            registerLocaleData(german);
            break;
          case Establishment.EnabledLanguagesEnum.Italian:
            registerLocaleData(italian);
            break;
          case Establishment.EnabledLanguagesEnum.Portuguese:
            registerLocaleData(portuguese);
            break;
          case Establishment.EnabledLanguagesEnum.Spanish:
            registerLocaleData(spanish);
            break;
        }

        this.translateService.use(language.toLowerCase());
        if (shouldReload) this.document.location.reload();
        return AppActions.changeLanguageSuccess({ language });
      }),
    ),
  );

  public sendEstablishmentVisit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.sendEstablishmentVisit),
      withLatestFrom(this.store.select(FromApp.selectVisitId)),
      filter(([, visitId]) => !visitId),
      mergeMap(([{ establishmentId }]) =>
        this.statisticsService.publicStatisticsControllerCreateEstablishmentVisit(establishmentId).pipe(
          map(visit => AppActions.sendEstablishmentVisitSuccess({ visitId: visit.id })),
          catchError(() => of(AppActions.sendEstablishmentVisitFailure()))
        )
      )
    )
  );

  public sendMenuVisit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.sendMenuVisit),
      mergeMap(({ menuId }) => this.statisticsService.publicStatisticsControllerCreateMenuVisit(menuId).pipe(
        map(() => AppActions.sendMenuVisitSuccess()),
        catchError(() => of(AppActions.sendMenuVisitFailure()))
      ))
    )
  );

  public sendProductVisit$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.sendProductVisit),
      mergeMap(({ productId }) => this.statisticsService.publicStatisticsControllerCreateProductVisit(productId).pipe(
        map(() => AppActions.sendProductVisitSuccess()),
        catchError(() => of(AppActions.sendProductVisitFailure()))
      ))
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private translateService: TranslateService,
    private statisticsService: StatisticsService,
    @Inject(DOCUMENT) private document: Document
  ) {}
}
