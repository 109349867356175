import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({ providedIn: "root" })
export class SeoService {

  constructor(private title: Title, private meta: Meta) {}

  public setMetadata(
    title: string = "Oh My Menu",
    description: string = "Tu carta digital.",
    image: string = "assets/img/hero_general.jpg",
  ): void {
    this.title.setTitle(title);
    this.meta.updateTag({ name: "description", content: description });

    this.meta.addTag({ property: "og:title", content: title });
    this.meta.addTag({ property: "og:description", content: description });
    this.meta.addTag({ property: "og:image", content: image });
  }
}
