import { ChangeDetectionStrategy, Component, HostListener } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { RootState } from "./store";
import * as AppActions from "src/app/store/app/actions";
import * as FromApp from "src/app/store/app/selectors";
import * as FromEstablishment from "src/app/store/establishment/selectors";
import { PublicEstablishment } from "src/app/store/establishment/state";
import { ActivationEnd, NavigationEnd, Router } from "@angular/router";
import { Establishment } from "./services/api";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public isMenuOpened: boolean;
  public isMenusSubmenuOpened: boolean;
  public isContactSectionOpened: boolean;
  public isMenusSectionOpened: boolean;
  public isFollowUsSectionOpened: boolean;
  public isHeaderSticky: boolean;
  public headerRouterClass?: string;
  public isMobileLayout: boolean = window.innerWidth < 992;
  public language$: Observable<Establishment.EnabledLanguagesEnum> = this.store.select(FromApp.selectLanguage);
  public loading$: Observable<boolean> = this.store.select(FromEstablishment.selectLoading);
  public notFound$: Observable<boolean> = this.store.select(FromEstablishment.selectNotFound);
  public establishment$: Observable<PublicEstablishment> = this.store.select(FromEstablishment.selectOne);

  constructor(private store: Store<RootState>, private router: Router) {
    this.isFollowUsSectionOpened = true;

    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.headerRouterClass = event.snapshot.data.headerClassName;
      } else if (event instanceof NavigationEnd) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    });
  }

  @HostListener("window:scroll", ["$event"])
  protected onWindowScroll(): void {
    this.isHeaderSticky = !!window.scrollY;
  }

  @HostListener("window:resize", ["$event"])
  protected onWindowResize(): void {
    this.isMobileLayout = window.innerWidth < 992;
  }

  public toggleMenu(): void {
    if (!this.isMobileLayout) return;

    this.isMenuOpened = !this.isMenuOpened;

    if (!this.isMenuOpened) {
      this.isMenusSubmenuOpened = false;
    }
  }

  public toggleMenus(): void {
    this.isMenusSubmenuOpened = !this.isMenusSubmenuOpened;
  }

  public toggleContact(): void {
    this.isContactSectionOpened = !this.isContactSectionOpened;
  }

  public toggleMenusSection(): void {
    this.isMenusSectionOpened = !this.isMenusSectionOpened;
  }

  public toggleFollowUsSection(): void {
    this.isFollowUsSectionOpened = !this.isFollowUsSectionOpened;
  }

  public changeLanguage(language: Establishment.EnabledLanguagesEnum): void {
    this.store.dispatch(AppActions.changeLanguage({ language }));
  }
}
