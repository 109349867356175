import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AppLanguages } from "src/app/constants";
import { Establishment } from "src/app/services/api";
import { PublicEstablishment } from "src/app/store/establishment/state";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  @Input() public establishment: PublicEstablishment;
  @Input() public isContactSectionOpened: boolean;
  @Input() public isMenusSectionOpened: boolean;
  @Input() public isFollowUsSectionOpened: boolean;
  @Input() public language: Establishment.EnabledLanguagesEnum;

  @Output() public toggleContact: EventEmitter<void> = new EventEmitter();
  @Output() public toggleMenusSection: EventEmitter<void> = new EventEmitter();
  @Output() public toggleFollowUsSection: EventEmitter<void> = new EventEmitter();
  @Output() public changeLanguage: EventEmitter<Establishment.EnabledLanguagesEnum> = new EventEmitter();

  public appLanguages: Establishment.EnabledLanguagesEnum[] = AppLanguages;

  constructor() {}

  public _changeLanguage(event: any): void {
    this.changeLanguage.emit(event.target.value);
  }
}
