import { RootState } from "src/app/store";
import { Store } from "@ngrx/store";
import * as AppActions from "src/app/store/app/actions";
import * as EstablishmentActions from "src/app/store/establishment/actions";
import * as FromApp from "src/app/store/app/selectors";
import * as FromEstablishment from "src/app/store/establishment/selectors";
import { first } from "rxjs/operators";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";

export function appInitFactory(store: Store<RootState>): () => Promise<void> {
  return async () => {
    store.select(FromApp.selectLanguage).pipe(first()).subscribe(language => store.dispatch(AppActions.changeLanguage({ language })));

    setTimeout(() => {
      store.select(FromEstablishment.selectOne).pipe(
        first()
      ).subscribe(establishment => {
        if (!establishment) {
          store.dispatch(EstablishmentActions.loadOneFailure({ error: { status: HttpStatusCode.NotFound } as HttpErrorResponse }));
        }
      });
    }, 4000);
  };
}
