import { ErrorHandler, Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class SentryService extends ErrorHandler {
  public environment$: Subscription;

  constructor() {
    super();
    this.init();
  }

  public handleError(error: any): void {
    super.handleError(error);
    try {
      if (/Loading chunk [\d]+ failed/.test(error.message)) window.location.reload();
      else Sentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
  }

  private init(): void {
    if (environment.production) {
      Sentry.init({
        release: `ohmymenu-menu@${environment.release}`,
        dsn: "https://a04be43ec06f4d41b9fa3ef10b02e0cc@o543353.ingest.sentry.io/5663431",
        environment: environment.name,
        attachStacktrace: true,
        autoSessionTracking: true,
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: ["localhost"],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
        tracesSampleRate: 1.0,
      });
    }
  }
}
