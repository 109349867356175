import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as AppActions from "./actions";

export const appReducer = createReducer(
  initialState,

  on(AppActions.changeLanguageSuccess, (state, { language }) => ({ ...state, language })),

  on(AppActions.sendEstablishmentVisitSuccess, (state, { visitId }) => ({ ...state, visitId })),
);
