import { ActionReducerMap } from "@ngrx/store";
import { AppState } from "./app/state";
import { appReducer as app } from "./app/reducer";
import { EstablishmentEffects } from "./establishment/effects";
import { establishmentReducer as establishment } from "./establishment/reducer";
import { EstablishmentState } from "./establishment/state";
import { AppEffects } from "./app/effects";

export interface RootState {
  app: AppState;
  establishment: EstablishmentState;
}

export const reducers: ActionReducerMap<RootState> = {
  app,
  establishment,
};

export const effects = [EstablishmentEffects, AppEffects];
