import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MapLangToIsoPipe } from "./map-lang-to-iso.pipe";

@NgModule({
  declarations: [MapLangToIsoPipe],
  exports: [MapLangToIsoPipe],
  imports: [CommonModule],
})
export class MapLangToIsoModule { }
