import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "./footer.component";
import { AppRoutingModule } from "src/app/app-routing.module";
import { TranslateModule } from "@ngx-translate/core";
import { MapLangToIsoModule } from "src/app/pipes/map-lang-to-iso/map-lang-to-iso.module";

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, AppRoutingModule, TranslateModule, MapLangToIsoModule],
  exports: [FooterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FooterModule { }
