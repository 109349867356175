import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Category, Menu, Product } from "src/app/services/api";

@Pipe({ name: "translation" })
export class TranslationPipe implements PipeTransform {
  constructor(private i18n: TranslateService) {}

  public transform(resource: Menu | Category | Product, field: "value" | "description" = "value"): string {
    if (!resource) return null;

    const translation = resource.translations.find(t => t.language.toLowerCase() === this.i18n.currentLang);

    if (!translation) {
      if (field === "value") return resource.name;
      else return (resource as (Menu|Product)).description || resource.name;
    } else {
      return translation[field];
    }

  }

}
