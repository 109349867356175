import { createAction, props } from "@ngrx/store";
import { Establishment } from "src/app/services/api";

export const changeLanguage = createAction("[App] Change Language", props<{ language: Establishment.EnabledLanguagesEnum }>());

export const changeLanguageSuccess = createAction(
  "[App] Change Language Success",
  props<{ language: Establishment.EnabledLanguagesEnum }>(),
);

export const sendEstablishmentVisit = createAction("[App] Send Establishment Visit", props<{ establishmentId: number }>());
export const sendEstablishmentVisitSuccess = createAction("[App] Send Establishment Visit Success", props<{ visitId: string }>());
export const sendEstablishmentVisitFailure = createAction("[App] Send Establishment Visit Failure");

export const sendMenuVisit = createAction("[App] Send Menu Visit", props<{ menuId: number }>());
export const sendMenuVisitSuccess = createAction("[App] Send Menu Visit Success");
export const sendMenuVisitFailure = createAction("[App] Send Menu Visit Failure");

export const sendProductVisit = createAction("[App] Send Product Visit", props<{ productId: number }>());
export const sendProductVisitSuccess = createAction("[App] Send Product Visit Success");
export const sendProductVisitFailure = createAction("[App] Send Product Visit Failure");
