/* eslint-disable @typescript-eslint/naming-convention */
import { Establishment } from "../services/api";

export const MAP_LANGUAGE_ENUM_TO_ISO: { [key in Establishment.EnabledLanguagesEnum]: string } = {
  CATALAN: "ca",
  ENGLISH: "en",
  FRENCH: "fr",
  GERMAN: "de",
  ITALIAN: "it",
  PORTUGUESE: "pt",
  SPANISH: "es",
};
