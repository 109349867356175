export const environment = {
  production: true,
  baseApi: "https://staging.api.ohmymenu.com",
  release: "0.0.97",
  name: "staging",
  firebase: {
    apiKey: "AIzaSyBZM3lyTqD7CJnFeXaGe2E-zaXK3Ahtq0M",
    authDomain: "com-ohmymenu.firebaseapp.com",
    databaseURL: "https://com-ohmymenu.firebaseio.com",
    projectId: "com-ohmymenu",
    storageBucket: "com-ohmymenu.appspot.com",
    messagingSenderId: "229737233171",
    appId: "1:229737233171:web:51c8fa10dde0f8813838e6",
    measurementId: "G-7DGV852SDT",
  },
};
