import { createSelector } from "@ngrx/store";
import { RootState } from "..";
import { Establishments, PublicEstablishment } from "./state";

export const selectLoading = (state: RootState) => state.establishment.loading;

export const selectNotFound = (state: RootState) => state.establishment.notFound;

export const selectFeature = (state: RootState) => state.establishment.establishments;

export const selectSelected = (state: RootState) => state.establishment.selected;

export const selectOne = createSelector(
  selectFeature,
  selectSelected,
  (establishments: Establishments, selected: string) => Object.values(establishments).find(establishment => establishment.slug === selected)
);

export const selectMenu = createSelector(
  selectOne,
  (establishment: PublicEstablishment, menuId: number) => establishment?.menus.find(menu => menu.id === menuId)
);
