import { Pipe, PipeTransform } from "@angular/core";
import { MapLanguageToISOCode } from "src/app/constants";
import { Establishment } from "src/app/services/api";

@Pipe({ name: "mapLangToIso" })
export class MapLangToIsoPipe implements PipeTransform {

  public transform(language: Establishment.EnabledLanguagesEnum): string {
    return MapLanguageToISOCode[language];
  }
}
