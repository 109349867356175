import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ActionReducer, MetaReducer, Store, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { EffectsModule } from "@ngrx/effects";
import { ApiModule, Configuration } from "./services/api";
import { effects, reducers, RootState } from "./store";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { appInitFactory } from "./app-init.factory";
import { FullscreenLoadingModule } from "src/app/components/fullscreen-loading/fullscreen-loading.module";
import { FooterModule } from "src/app/components/footer/footer.module";
import { HeaderModule } from "./components/header/header.module";
import { AngularFireAnalyticsModule, APP_NAME, APP_VERSION, DEBUG_MODE, ScreenTrackingService } from "@angular/fire/compat/analytics";
import { AngularFireModule } from "@angular/fire/compat";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { SentryService } from "./services/sentry/sentry.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { DefaultLanguage } from "./constants";
import { createTranslateLoader } from "./services/i18n/translate-loader";
import { localStorageSync } from "ngrx-store-localstorage";

export function setApiConfig(): Configuration {
  return new Configuration({ basePath: environment.baseApi });
}

export function localStorageReduxSync(reducer: ActionReducer<RootState>): ActionReducer<any> {
  return localStorageSync({ keys: [{ app: ["language"] }], rehydrate: true })(reducer);
}

const metaReducers: MetaReducer<any, any>[] = [localStorageReduxSync];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      defaultLanguage: DefaultLanguage.toLowerCase(),
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    HttpClientModule,
    FullscreenLoadingModule,
    FooterModule,
    HeaderModule,
    ApiModule.forRoot(setApiConfig),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot(effects),
    AngularFireModule.initializeApp(environment.firebase, "com-ohmymenu-menu"),
    AngularFireAnalyticsModule,
  ],
  providers: [
    ScreenTrackingService,
    { provide: APP_INITIALIZER, useFactory: appInitFactory, deps: [Store], multi: true },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: ErrorHandler, useClass: SentryService },
    { provide: APP_NAME, useValue: "com-ohmymenu-menu" },
    { provide: APP_VERSION, useValue: environment.release },
    { provide: DEBUG_MODE, useValue: !environment.production },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
