/**
 * Oh My Menu
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.3.07
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Timetable } from './timetable';
import { Translation } from './translation';
import { User } from './user';


export interface Establishment { 
    id?: number;
    userId: number;
    user?: User;
    googlePlaceId?: string;
    autoUpdateInfoFromGoogle: boolean;
    name: string;
    slug?: string;
    description?: string;
    currency: Establishment.CurrencyEnum;
    website?: string;
    phoneNumber?: string;
    email?: string;
    logo?: string;
    image?: string;
    color?: string;
    instagram?: string;
    spotify?: string;
    facebook?: string;
    twitter?: string;
    pinterest?: string;
    tripadvisor?: string;
    enabledLanguages?: Array<Establishment.EnabledLanguagesEnum>;
    formattedAddress?: string;
    latitude?: number;
    longitude?: number;
    timezone?: string;
    timetables: Array<Timetable>;
    translations: Array<Translation>;
    createdAt?: string;
    updatedAt?: string;
}
export namespace Establishment {
    export type CurrencyEnum = 'EURO' | 'BRITISHPOUND' | 'UNITEDSTATESDOLLAR';
    export const CurrencyEnum = {
        Euro: 'EURO' as CurrencyEnum,
        Britishpound: 'BRITISHPOUND' as CurrencyEnum,
        Unitedstatesdollar: 'UNITEDSTATESDOLLAR' as CurrencyEnum
    };
    export type EnabledLanguagesEnum = 'SPANISH' | 'ENGLISH' | 'CATALAN' | 'GERMAN' | 'FRENCH' | 'ITALIAN' | 'PORTUGUESE';
    export const EnabledLanguagesEnum = {
        Spanish: 'SPANISH' as EnabledLanguagesEnum,
        English: 'ENGLISH' as EnabledLanguagesEnum,
        Catalan: 'CATALAN' as EnabledLanguagesEnum,
        German: 'GERMAN' as EnabledLanguagesEnum,
        French: 'FRENCH' as EnabledLanguagesEnum,
        Italian: 'ITALIAN' as EnabledLanguagesEnum,
        Portuguese: 'PORTUGUESE' as EnabledLanguagesEnum
    };
}


