import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FullscreenLoadingComponent } from "./fullscreen-loading.component";

@NgModule({
  declarations: [FullscreenLoadingComponent],
  imports: [CommonModule],
  exports: [FullscreenLoadingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FullscreenLoadingModule { }
