import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as EstablishmentActions from "./actions";

export const establishmentReducer = createReducer(
  initialState,

  on(EstablishmentActions.loadOne, state => ({ ...state, loading: true })),

  on(EstablishmentActions.loadOneFailure, state => ({ ...state, loading: false, notFound: true })),

  on(EstablishmentActions.loadOneSuccess, (state, { establishment }) => ({
    ...state,
    establishments: {
      ...state.establishments,
      [establishment.id]: establishment,
    },
    selected: establishment.slug,
    loading: false,
  })),
);
