<header class="header black_nav clearfix element_to_stick {{customClass}}" [class.sticky]="sticky">
  <div class="container">
    <div id="logo">
      <a *ngIf="establishment?.logo" [routerLink]="['home', establishment?.slug]">
        <img [src]="establishment?.logo" [alt]="establishment?.name">
      </a>
      <a *ngIf="!establishment" href="https://ohmymenu.com">
        <img src="assets/icon/logox2.png" [alt]="'General.logoAlt' | translate">
      </a>
    </div>

    <div class="layer" [class.layer-is-visible]="isMenuOpened" (click)="toggleMenu.emit()"></div>

    <a class="open_close" (click)="toggleMenu.emit()">
      <i class="icon_menu"></i><span>Menu</span>
    </a>

    <nav class="main-menu" [class.show]="isMenuOpened">
      <div id="header_menu">
        <a class="open_close" (click)="toggleMenu.emit()">
          <i class="icon_close"></i><span>Menu</span>
        </a>
        <a *ngIf="establishment" [routerLink]="['home', establishment?.slug]">
          <img [src]="establishment?.logo || 'assets/icon/logox2.png'" [alt]="establishment?.name" [style.maxHeight.px]="50">
        </a>
        <a *ngIf="!establishment" href="https://ohmymenu.com">
          <img src="assets/icon/logox2.png" [alt]="'General.logoAlt' | translate" [style.maxHeight.px]="50">
        </a>
      </div>

      <ul *ngIf="establishment">
        <li>
          <a [routerLink]="['home', establishment?.slug]" (click)="toggleMenu.emit()">
            {{ "General.home" | translate }}
          </a>
        </li>

        <li class="submenu">
          <a class="show-submenu" (click)="toggleMenus.emit()">
            {{ "General.menus" | translate }}
          </a>
          <ul [class.show_normal]="isMenusSubmenuOpened">
            <li *ngFor="let menu of establishment?.menus">
              <a class="submenu-link" [routerLink]="['home', establishment?.slug, 'menu', menu.id]" (click)="toggleMenu.emit()">
                {{ menu | translation }}
              </a>
            </li>
          </ul>
        </li>

        <li>
          <a [routerLink]="['home', establishment?.slug, 'contact']" (click)="toggleMenu.emit()">
            {{ "General.contact" | translate }}
          </a>
        </li>
      </ul>

      <ul *ngIf="!establishment">
        <li>
          <a href="https://ohmymenu.com/{{language|mapLangToIso}}">
            {{ "General.publishYourMenu" | translate }}
          </a>
        </li>
      </ul>

      <ul class="powered-by" (click)="openLanding()">
        <li class="powered-by__logo">
          <img class="powered-by__logo__img" src="assets/icon/logox1.png" />
          <span class="powered-by__logo__text">Oh My Menu ®</span>
        </li>
      </ul>
    </nav>
  </div>
</header>
