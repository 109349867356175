<footer>
  <div class="wave gray footer"></div>
  <div class="container margin_60_40 fix_mobile">

    <div *ngIf="establishment" class="row">
      <div class="col-lg-3 col-md-6">
        <h3 [class.opened]="isMenusSectionOpened" (click)="toggleMenusSection.emit()">
          {{ "General.menus" | translate }}
        </h3>
        <div class="collapse dont-collapse-sm links" [class.show]="isMenusSectionOpened">
          <ul>
            <li *ngFor="let menu of establishment?.menus">
              <a [routerLink]="['home', establishment?.slug, 'menu', menu.id]">
                {{ menu.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6" *ngIf="establishment?.formattedAddress || establishment?.phoneNumber || establishment?.website">
          <h3 [class.opened]="isContactSectionOpened" (click)="toggleContact.emit()">
              {{ "General.contact" | translate }}
          </h3>
          <div class="collapse dont-collapse-sm contacts" [class.show]="isContactSectionOpened">
            <ul>
              <li *ngIf="establishment?.formattedAddress">
                <i class="icon_house_alt"></i>
                <a [href]="'https://www.google.com/maps?saddr&daddr=' + establishment?.formattedAddress" target="_blank">
                  {{ establishment?.formattedAddress }}
                </a>
              </li>
              <li *ngIf="establishment?.phoneNumber">
                <i class="icon_mobile"></i>
                <a [href]="'tel:' + establishment?.phoneNumber">
                  {{ establishment?.phoneNumber }}
                </a>
              </li>
              <li *ngIf="establishment?.website">
                <i class="icon_laptop"></i>
                <a [href]="establishment?.website" target="_blank">
                  {{ establishment?.website }}
                </a>
              </li>
            </ul>
          </div>
      </div>

      <div class="col-lg-3 col-md-6" *ngIf="establishment?.twitter || establishment?.spotify || establishment?.facebook || establishment?.instagram || establishment?.tripadvisor">
        <h3 [class.opened]="isFollowUsSectionOpened" (click)="toggleFollowUsSection.emit()">
          {{ "General.followUs" | translate }}
        </h3>
        <div class="follow_us collapse dont-collapse-sm contacts" [class.show]="isFollowUsSectionOpened">
          <ul>
            <li *ngIf="establishment?.twitter">
              <a [href]="establishment?.twitter" target="_blank">
                <img src="assets/img/twitter_icon.svg" class="lazy">
              </a>
            </li>
            <li *ngIf="establishment?.spotify">
              <a [href]="establishment?.spotify" target="_blank">
                <img src="assets/img/spotify_icon.svg" class="lazy">
              </a>
            </li>
            <li *ngIf="establishment?.facebook">
              <a [href]="establishment?.facebook" target="_blank">
                <img src="assets/img/facebook_icon.svg" class="lazy">
              </a>
            </li>
            <li *ngIf="establishment?.instagram">
              <a [href]="establishment?.instagram" target="_blank">
                <img src="assets/img/instagram_icon.svg" class="lazy">
              </a>
            </li>
            <li *ngIf="establishment?.tripadvisor">
              <a [href]="establishment?.tripadvisor" target="_blank">
                <img src="assets/img/tripadvisor_icon.svg" class="lazy">
              </a>
            </li>
            <li *ngIf="establishment?.pinterest">
              <a [href]="establishment?.pinterest" target="_blank">
                <img src="assets/img/pinterest_icon.svg" class="lazy">
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <hr>
    <div class="row add_bottom_25">
      <div class="col-lg-6">
        <ul class="footer-selector clearfix">
          <li>
            <div class="styled-select lang-selector">
              <select [value]="language" (change)="_changeLanguage($event)">
                <option
                  *ngFor="let lang of (establishment?.enabledLanguages) || appLanguages"
                  [value]="lang"
                  [attr.selected]="lang === language ? '' : undefined"
                >
                  {{ "Language." + lang | translate }}
                </option>
            </select>
            </div>
          </li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div class="col-lg-6">
        <ul class="additional_links">
          <li><a href="https://ohmymenu/{{language|mapLangToIso}}/terms" target="_blank">
            {{ "General.termsOfUse" | translate }}
          </a></li>
          <li><a href="https://ohmymenu/{{language|mapLangToIso}}/privacy" target="_blank">
            {{ "General.privacy" | translate }}
          </a></li>
          <li><span><a href="https://ohmymenu.com/{{language|mapLangToIso}}"><b>® OhMyMenu</b></a></span></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
