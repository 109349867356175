import { Component, Input } from "@angular/core";

@Component({
  selector: "app-fullscreen-loading",
  templateUrl: "./fullscreen-loading.component.html",
  styleUrls: ["./fullscreen-loading.component.scss"],
})
export class FullscreenLoadingComponent {
  @Input() public show: boolean;

  constructor() { }

}
