import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MapLanguageToISOCode } from "src/app/constants";
import { Establishment } from "src/app/services/api";
import { PublicEstablishment } from "src/app/store/establishment/state";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  @Input() public establishment: PublicEstablishment;
  @Input() public sticky: boolean;
  @Input() public isMenuOpened: boolean;
  @Input() public isMenusSubmenuOpened: boolean;
  @Input() public customClass?: string;
  @Input() public language: Establishment.EnabledLanguagesEnum;

  @Output() public toggleMenu: EventEmitter<void> = new EventEmitter();
  @Output() public toggleMenus: EventEmitter<void> = new EventEmitter();

  constructor() {}

  public openLanding(): void {
    open(`https://ohmymenu.com/${MapLanguageToISOCode[this.language]}`, "_blank");
  }
}
