import { DefaultLanguage } from "src/app/constants";
import { Establishment } from "src/app/services/api";

export interface AppState {
  language: Establishment.EnabledLanguagesEnum;
  visitId?: string;
}

export const initialState: AppState = {
  language: DefaultLanguage,
};
