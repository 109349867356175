import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import * as constants from "src/app/constants";

const routes: Routes = [
  {
    path: `${constants.Routes.Home}/:slug`,
    loadChildren: () => import("./pages/home/home.module").then(m => m.HomePageModule),
  },
  {
    path: `${constants.Routes.Home}/:slug/${constants.Routes.Contact}`,
    loadChildren: () => import("./pages/contact/contact.module").then(m => m.ContactPageModule),
  },
  {
    path: `${constants.Routes.Home}/:slug/${constants.Routes.Menu}/:menuId`,
    loadChildren: () => import("./pages/menu/menu.module").then(m => m.MenuPageModule),
    data: { headerClassName: "header_in" },
  },
  {
    path: constants.Routes.NotFound,
    loadChildren: () => import("./pages/not-found/not-found.module").then(m => m.NotFoundPageModule),
    data: { headerClassName: "header_in" },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
