<app-fullscreen-loading
  [show]="(!(establishment$ | async)?.id || (loading$ | async)) && !(notFound$ | async)"
></app-fullscreen-loading>

<app-header
  [establishment]="establishment$ | async"
  [sticky]="isHeaderSticky"
  [customClass]="headerRouterClass"
  [isMenuOpened]="isMenuOpened"
  [isMenusSubmenuOpened]="isMenusSubmenuOpened"
  [language]="language$ | async"
  (toggleMenu)="toggleMenu()"
  (toggleMenus)="toggleMenus()"
></app-header>

<router-outlet></router-outlet>

<app-footer
  [establishment]="establishment$ | async"
  [isContactSectionOpened]="isContactSectionOpened"
  [isMenusSectionOpened]="isMenusSectionOpened"
  [isFollowUsSectionOpened]="isFollowUsSectionOpened"
  [language]="language$ | async"
  (toggleContact)="toggleContact()"
  (toggleMenusSection)="toggleMenusSection()"
  (toggleFollowUsSection)="toggleFollowUsSection()"
  (changeLanguage)="changeLanguage($event)"
></app-footer>
